.app{
  min-height: 100vh;
  width:100%;
  background:linear-gradient(135deg, rgba(11, 110, 205, 0.03), rgba(1, 207, 254, 0.03));
  padding-left:5vw;
  padding-right:5vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.feed{
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  width:100%;
  height:100%;
  box-sizing: border-box;
}

.feedLeft{
  width:30%;
  padding: 20px;
  box-sizing: border-box;
}

.feedRight{
  width:70%;
  padding: 20px;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  
  .feedLeft{
    width:90vw;
  }
  .feedRight{
    width:90vw;
  }
}

.gradientBackground{
  background: linear-gradient(135deg, #0B6BCB, #00D1FF);
}

.gradientText {
  font-size: 3em;
  background: -webkit-linear-gradient(135deg, #0B6BCB, #00D1FF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* The above two lines are for webkit browsers, like Chrome and Safari */

  /* For other browsers that support background-clip:text (like Firefox 62+) */
  background-clip: text;
  color: transparent; /* Fallback: On browsers that don't support background-clip:text */
}



.feedFilterCard{
  border-radius:10px;
  padding:20px;
  box-sizing: border-box;
  color:white;
}

.textMedium{
  font-size: 16px;
  font-weight: 500;
}

.navBar{
  width:100%;
  padding:20px;
  color:#0B6DCC;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.navLinks{
  display: flex;
  gap: 30px;
  font-weight: 700;
  font-size:14px;
  align-items: center;
  cursor: pointer;
}


.jobCard {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  font-family: 'Arial', sans-serif;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.jobCardHeader {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.profilePic {
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  border-radius: 50%;
  margin-right: 20px;
  cursor: pointer;
  min-width:50px;
}

.jobTitle {
  font-size: 1.25rem;
  color: #333;
  margin: 0;
}

.jobBudget {
  padding: 5px 7px;
  border-radius: 5px;
  font-size:13px;
  margin-top:5px;
  font-weight: bold;
  display: inline-block; /* To align with the title */
  color:white;
}

.jobDescription {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 10px;
  color: #0B6DCC;
  word-wrap: break-word;
}

.jobRequirements{
  margin-left:5px;
  padding-left:5px;
  border-left: 3px solid #0B6BCB;
  font-size:14px;
  color:#0B6BCB;
  word-wrap: break-word;
}

.jobFooter {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap:10px;
}

.jobTags {
  display: flex;
  flex-wrap: nowrap;
  overflow: scroll;
  gap: 10px;
  padding-right:25px;
  box-sizing: border-box;
}

.jobTags::-webkit-scrollbar {
  background: transparent; /* Chrome/Safari/Webkit */
  width: 0px;
}
    
.jobTags {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
}

.applyButton {
  color: #ffffff;
  padding: 10px 30px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  outline: none;
  text-decoration: none;
}

.jobTag {
  position: relative;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  text-wrap:nowrap;
  color:#0B6DCC;
  font-weight:600;
  height:20px;
}

.jobTag::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50px;
  padding: 2px; /* control the border thickness */
  background: linear-gradient(45deg, #0B6BCB, #00D1FF);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude;
  pointer-events: none;
}


.resetFilters{
  color: white;
  border: 1.5px solid white;
  font-size:16px;
  border-radius:20px;
  width:80px;
  text-align: center;
  padding:5px;
  margin-left:auto;
  margin-top:10px;
  cursor:pointer;
  transition: 0.2s;
}

.resetFilters:hover{
  background:white;
  color:#0B6DCC;
}

.brand{
  font-size:21px;
  font-weight:700;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.07);
  display: flex;
  align-items: center;
  gap:5px;
  cursor: pointer;
}

.applyJobHeader{
  background: linear-gradient(135deg, #0B6BCB, #00D1FF);
  width:100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  padding:30px;
  box-sizing: border-box;
  color:white;
  font-weight:700;
}

.applyJobWrapper{
  display: flex;
  padding:30px;
  padding-top:10px;
  max-width:100vw;
}

.applyJobLeft{
  width:70%;
  padding-right:40px;
  box-sizing: border-box;
}

.applyJobRight{
  width:30%;
}

@media screen and (max-width: 768px) {
  .applyJobWrapper{
    flex-direction: column-reverse;
  }
  .applyJobLeft{
    width:100%;
    padding-right:0px;
  }
  .applyJobRight{
    width:100%;
    margin-bottom:20px;
  }
}

.applyJobProviderCard{
  background: linear-gradient(135deg, #0B6BCB, #00D1FF);
  color:white;
  border-radius:20px;
  padding:20px;
  box-sizing:border-box;
}

.applyJobProviderCardTitle{
  font-size:20px;
  font-weight:700;
}

.inputDescriptor{
  font-size:14px;
  font-weight:600;
  color:#0B6DCC;
  margin-top:10px;
}

.milestone{
  padding-left:10px;
  box-sizing: border-box;
  margin-top:20px;
  position: relative;

}

.milestone::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 0px;
  padding-left: 4px; /* control the border thickness */
  background: linear-gradient(180deg, #0B6BCB, #00D1FF);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude;
  pointer-events: none;
}

.milestone-header{
  display: flex;
  flex-wrap: nowrap;
  gap:10px;
  margin-bottom:10px;
}

.addMilestoneButton{
  color:white;
  padding:5px;
  border-radius:10px;
  border:none;
  margin-top:5px;
  cursor:pointer;
}

.submitApplicationButton{
  padding:10px;
  color:white;
  font-weight:700;
  text-align: center;
  border-radius:15px;
  margin-top:10px;
  cursor: pointer;
}


.workFeed{
  display: flex;
  flex-direction: row;
  width:100%;
  color:#0B6BCB
}

.workFeedLeft{
  width:30%;
  padding: 20px;
}

.workFeedRight{
  width:70%;
  padding: 20px;
}

.workFeedFilterCard{
  background-color: white;
  border-radius:20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding-bottom:20px;
}

.workFeedFilterCardHeader{
  border-radius:20px 20px 0px 0px;
  width:100%;
  padding:20px;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.workFeedFilterCardHeaderButtons{
  display: flex;
  justify-content: center;
  align-items: center;
  color:white;
  padding:10px;
  box-sizing: border-box;
  border-radius:20px 20px 0px 0px;
}

.workFeedFilter{
  background:white;
  color:#0B6BCB;
  height:80px;
  width:33.3%;
  font-size:12px;
  text-align:center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor:pointer;
  transition:0.2s;
}

.workFeedFilter:hover{
  background-color: #f0f6ff;
}


.workFeedFilterSelected{
  transform:scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.resetFiltersWorkFeed{
  margin-left:auto;
  margin-right:10px;
  color:white;
  width:50px;
  font-size:13px;
  cursor: pointer;
}

.areaDescriptor{
  color:white;
  font-size:14px;
  font-weight:600;
}

.workFeedFilterWorkCards{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap:20px;
  margin-top:20px;
  margin-bottom:20px;
  justify-content: center;
}


.workFeedFilterWorkCard{
  padding:20px;
  box-sizing: border-box;
  box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
  border-radius:20px;
  margin-left:20px;
  margin-right:20px;
  margin-top:10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap:20px;
  transition: 0.2s;
  cursor: pointer;
}

.workFeedFilterWorkCard:hover{
  transform:scale(1.05)
}

.workFeedFilterWorkCardTitle{
  font-size:14px;
  font-weight:700;
  word-wrap: break-word;
}

.workFeedFilterDetails{
  text-wrap: nowrap;
  font-size:13px;
  padding:5px 10px;
  border-radius:10px;
  color:white;
  cursor: pointer;
}

.workFeedMainCard{
  padding:20px;
  background-color:white;
  border-radius:20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom:20px;
}

.workFeedJobCardTitle{
  font-size:20px;
  font-weight:700;
}

.workFeedMilestoneCard{
  padding:20px;
  background-color:white;
  border-radius:20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top:20px;
}

.milestoneNumber{
  font-size:14px;
  font-weight: 700;
}

.workFeedMilestoneCardHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom:10px;
}

.workFeedMilestoneTitle{
  font-weight:700;
  font-size:14px;
}

.workFeedMilestoneDescription{
  font-size:14px;
  color:#0B6BCB;
  margin-bottom:20px;
}


.getStarted{
  display:flex;
  align-items: center;
  justify-content: center;
  height:100%;
  width:100%;
  box-sizing: border-box;
  flex-grow: 1;
}


.createProfileCard{
  background: white;
  border-radius:20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  color:#0B6BCB;
  margin-top:20px;
  margin-bottom:20px;
  width:50vw;
}

@media screen and (max-width: 768px) {
  .createProfileCard{
    width:90vw;
  }
}

.createProfileCardHeader{
  background: linear-gradient(135deg, #0B6BCB, #00D1FF);
  color:white;
  border-radius:20px;
  padding:20px;
  box-sizing:border-box;
  font-size:18px;
  font-weight:700;
}

.createProfileCardBody{
  padding:20px;
  padding-top:0px;
  box-sizing:border-box;
}

.createProfileSocials{
  display: flex;
  gap:10px;
}

/*FILE UPLOAD*/
.file-upload{
  display:flex;
  justify-content: flex-start;
  width:100%;
}

.file-upload-button{
    color: #00A3FF;
    border:1px solid #00A3FF;
    border-radius: 10px;
    padding:10px;
    background-color: white;
    cursor: pointer;
}

.dropzone {
  border: 1px dashed #ccc;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  width:100%;
  box-sizing: border-box;
  height:100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropzone.drag-active {
  border-color: #007bff;
  background-color: #f3f3f3;
}

.upload-logo-button{
  color: #00A3FF;
  border:1px solid #00A3FF;
  border-radius: 10px;
  padding:10px;
  background-color: white;
  cursor: pointer;
  align-self:flex-end;
  width:100%;
}

.upload-logo-area{
  display: flex;
  width:100%;
  gap:10px;
  flex-direction: column;
}

.createProfileButtons{
  display: flex;
  gap:10px;
  margin-top:10px;
  justify-content:flex-end;
}

.voting{
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height:100%;
  width:100%;
  box-sizing: border-box;
  flex-grow: 1;
  margin-top:100px;
}

.pollCard{
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  width:50vw;
  box-sizing: border-box;
}

.pollDescription{
  color: gray;
  margin-bottom:10px;
  word-wrap: break-word;
}

.pollAnswerText{
  color:#007bff;
  font-weight:700;
  font-size:14px;
  margin-bottom:3px;
}

.pollAnswer{
  margin-bottom:10px;
}

.pollButton{
  width:100%;
  text-align:center;
  font-weight:700;
}

.pollButtons{
  display:flex;
  gap:10px;
  width:100%;
  justify-content: stretch;
  box-sizing: border-box;
  flex-grow: 1;
  margin-top:30px;
}

.votingMenu{
  width:50vw;
  margin-bottom:20px;
}

.navBarMobile{
  display:none;
}

.mobileMenu{
  display:flex;
  flex-direction: column;
  gap:30px;
  padding:20px;
  color:#0B6DCC;
  font-weight:700;
  box-sizing: border-box;
  align-items: flex-start;
}

@media screen and (max-width: 768px) {
  .votingMenu{
    width:90vw;
  }
  .pollCard{
    width:90vw;
  } 
  .navBarMobile{
    display: flex;
    width:100%;
    padding:20px;
    color:#0B6DCC;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
  }
}




.profilePage{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:100%;
  width:100%;
  box-sizing: border-box;
  flex-grow: 1;
}

.profilePageCard{
  background: white;
  border-radius:20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  color:#0B6BCB;
  margin-top:20px;
  margin-bottom:20px;
  width:50vw;
}


.profilePageCardHeader{
  background: linear-gradient(135deg, #0B6BCB, #00D1FF);
  width:100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  padding:30px;
  box-sizing: border-box;
  color:white;
  font-weight:700;
  border-radius: 20px 20px 0px 0px;
  font-size:24px;
  gap:10px;
}

.profilePageCardBody{
  padding:30px;
  box-sizing:border-box;
  width:100%;
  display: flex;
  flex-direction: column;
}

.profilePageCardBodyTop{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom:20px;
  width:100%;
  gap:10px;
}

.profilePageCardBodyTopLeft{
  display: flex;
  flex-direction: column;
  gap:10px;
  width:60%;
}

.profilePageCardBodyTopRight{
  display: flex;
  flex-direction: column;
  gap:10px;
  border-radius:20px;
  width:30%;
  color:white;
  padding:20px;
}

@media screen and (max-width: 768px) {
  .profilePageCard{
    width:90vw;
  }
  .profilePageCardBodyTop{
    flex-direction: column;
  }
  .profilePageCardBodyTopLeft{
    width:100%;
  }
  .profilePageCardBodyTopRight{
    width:90%
  }
  
}


.profilePageCardBodyButtons{
  display: flex;
  gap:10px;
  margin-top:10px;
  justify-content:flex-start;
  flex-wrap:wrap;
}

.seePaymentsButton{
  padding:3px 10px;
  color:white;
  border:2px solid white;
  border-radius:20px;
  width:100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor:pointer;
  margin-top:10px;
  font-size:13px;
}

.editProfileButton{
  margin-left:auto;
  cursor:pointer;
}





.scrollable-container {
  display: flex;
  overflow-x: auto;
  position: relative; /* This sets up the positioning context */
  scrollbar-width: none; /* for Firefox */
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  padding:5px;
  padding-right:50px;
  box-sizing: border-box;
  width:100%;
}

.scrollable-container::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

@media screen and (max-width: 768px){
  .scrollable-container{
    width:300px;
  }
}

.gradient-container {
  position: relative;
  flex: none; /* This ensures the gradient container does not flex */
}

.fade-effect-right {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100px; /* Adjust as needed */
  height:110px;
  background: linear-gradient(to right, rgba(255,255,255,0), rgb(255, 255, 255) 70%);
  pointer-events: none; /* Allows clicks to pass through */
}



.reviewCard{
    width: 350px;
    margin-right:10px;
    height:115px;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0px 8px rgba(0, 0, 0, 0.1);
    background: white;
    box-sizing: border-box;
    flex: 0 0 auto;
    position:relative;
}

.scroll-button{
  position:absolute;
  right:0;
  top:40px;
  z-index:1;
  cursor:pointer;
  opacity:0.7;
}

.scroll-button-back{
  position:absolute;
  left:-10px;
  top:40px;
  z-index:1;
  cursor:pointer;
  opacity:0.7;

}

.scroll-left-image{
  transform:rotate(180deg);
}

.reviewCardTop{
  display: flex;
  align-items: flex-start;
  gap:10px;
  position:relative;
}

.reviewCardText{
  font-size:14px;
  word-wrap: break-word;
}

.landing{
  min-height:100vh;
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap:wrap;
}

.landingLeft{
  flex:0.5;
  display: flex;
  flex-direction: column;
  gap:20px;
  align-items: flex-start;
  justify-content: center;
  z-index:10;
  padding-left: 10vw;
}

.landingRight{
  flex:0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10vw;
}

.landingTitle{
  font-size:72px;
  font-weight:700;
  color:white;
  z-index:100;
}

.landingDescription{
  font-size:18px;
  font-weight:500;
  color:white;
  z-index:100;
}

.getStartedButton{
  box-shadow: 0px 0px 20px 20px #0b6bcb41; /* This creates the shadow effect */
  border: 2px solid white;
}

.background {
  width: 100%;
  height: 100vh;
  position: absolute;
  overflow: hidden;
}

.gradient-shape {
  position: absolute;
  background: inherit; /* This makes sure the shape inherits the gradient */
  background: linear-gradient(45deg, #0B6BCB, #00D1FF);
  opacity:0.8;

  /* Add more specific styling for the shape here */
}

.shape1 {
  /* Define specific attributes for shape 1 */
  width: 2500px;
  height: 2000px;
  transform: rotate(60deg);
  border-radius: 100px; /* makes it a circle, adjust as needed */
  top: -1700px;
  left: -700px;
  box-shadow: 0px 0px 50px 20px #0b6bcb73; /* This creates the shadow effect */

}

.shape2 {
  /* Define specific attributes for shape 2 */
  width: 300px;
  height: 300px;
  border-radius: 50%; /* makes it a circle, adjust as needed */
  bottom: 10%;
  right: 10%;
}

.shape3 {
  /* Define specific attributes for shape 2 */
  width: 500px;
  height: 500px;
  transform: rotate(60deg);
  border-radius: 100px; /* makes it a circle, adjust as needed */
  top:100px;
  left: 10%;
  z-index:1;
  filter:blur(10px);
  opacity:0.5;
}

.demo{
  border-radius:20px;
  width:30vw;
  height:35vh;
  box-shadow: rgba(99, 99, 99, 0.5) 0px 2px 20px 0px;
  z-index:10;
}

@media (max-width: 768px) {
  .landingLeft{
    align-items: center;
    justify-content: center;
    padding:0;
    margin-top:50px;
  }

  .landingRight{
    align-items: center;
    justify-content: center;
    padding:0;
  }

  .demo{
    width:80vw;
    height:40vh;
  }
}

.landingCards{
  display: flex;
  gap:30px;
  align-items: center;
  justify-content: center;
  flex-wrap:wrap;
}


.landingCard{
  padding:20px;
  border-radius:20px;
  background: white;
  box-sizing: border-box;
  flex: 0 0 auto;
  width: 300px;
  height: 150px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap:10px;
  cursor:pointer;
  transition:0.2s;
  color:#4f4f4f;
  text-align: center;
}

.landingCardText{
  display:flex;
  flex-direction: column;
  text-align:left;
}

@media screen and (max-width: 768px) {
  .landingCards{
    gap:10px;
  }  
}


.landingCardHeader{
  font-size:24px;
  font-weight:700;
}

.sponsorsTitle{
  width:100%;
  text-align:center;
  font-size:48px;
  color:#4f4f4f;
  font-weight:700;
}

.landingNav{
  position:absolute;
  top:40px;
  z-index:10;
  color:white;
  font-size:16px;
  font-weight: 700;
  display: flex;
  gap:30px;
}

@media screen and (max-width: 768px) {
  .navBar{
    display:none;
  }
  
}

.createJob{
  display:flex;
  align-items: center;
  justify-content: center;
  height:100%;
  width:100%;
  box-sizing: border-box;
  flex-grow: 1;
}

.payCustomTokenButton{
  font-weight:700;
  font-size:14px;
  cursor: pointer;
}

.milestoneButtons{
  display: flex;
  gap:10px;
  margin-top:10px;
}







/* LOADER */
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  top:calc(50vh - 40px);
  left:calc(50vw - 40px);
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: linear-gradient(45deg, #0B6BCB, #00D1FF);
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@media screen and (max-width: 768px) {
  .workFeed{
    flex-direction: column;
    width:100%;
    box-sizing: border-box;
  }
  .workFeedLeft{
    width:100%;
    justify-content: center;
    box-sizing: border-box;

  }
  .workFeedRight{
    width:100%;
    box-sizing: border-box;
  }

  .workFeedFilterWorkCards{
    max-height:25vh;
    overflow-y:scroll;
    flex-direction: column;
    flex-wrap: nowrap;
    padding-bottom:20px;
  }
}

.swal2-container {
  z-index: 99999;
}

.depositWrapper{
  display: flex;
  justify-content:space-between;
  align-items: center;
}

.refetchDeposit{
  cursor:pointer;
  transition: 0.2s;
}

.refetchDeposit:hover{
  transform: scale(1.1);
}

.noJobs{
  width:100%;
  font-style:italic;
  text-align:center;
}

.milestoneStatus{
  font-size:13px;
  border:1px solid #0B6BCB;
  border-radius:5px;
  padding:2px 5px;
  max-width:100px
}

.workProposalDetailsButtons{
  display: flex;
  margin-top:30px;
  gap:10px;
  margin-top:10px;
}

.replyReview{
  position:absolute;
  font-size:13px;
  top:10px;
  right:10px;
  transition:0.2s;
  cursor:pointer;
  z-index:1;

}

.replyReview:hover{
  transform:scale(1.1)
}

.readReview{
  position:absolute;
  font-size:13px;
  bottom:10px;
  right:10px;
  transition:0.2s;
  cursor:pointer;
  z-index:1;
}

.readReview:hover{
  transform:scale(1.1)
}

.reviewreply{
  border-left: 3px solid #0B6BCB;
  padding-left:10px;
  margin-left:10px;
}

.workProviderWorkWorkerInfo{
  color: #0B6BCB;
  display:flex;
  gap:10px;
  align-items: center;
  justify-content: flex-start;
  margin-top:15px;
}

.workFeedMilestoneSubmissionComment{
  font-size:14px;
}

.shareJob{
  border: 2px solid white;
  border-radius: 10px;
  padding:5px 10px;
  cursor: pointer;
}

.landingSocialLink{
  color:white;
  cursor:pointer;
  text-decoration: none;
  transition: 0.2s;
}

.landingSocialLink:hover{
  transform: scale(1.1);
}

.accordionWrapper{
  display:flex;
  align-items: center;
  justify-content: center;
  padding-bottom:50px;
}

.accordionContainer{
  width:60vw;
}

@media screen and (max-width: 768px) {
  .accordionContainer{
    width:85vw;
  }
  
}


.modalCardTos {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 30vw;
  background: white;
  border-radius: 10px;
  border: none;
  outline: none;
  overflow: hidden;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.3);
  animation: scaler 0.3s normal forwards ease-in-out;
  -webkit-animation: scaler 0.3s normal forwards ease-in-out;
  -moz-animation: scaler 0.3s normal forwards ease-in-out;
  -o-animation: scaler 0.3s normal forwards ease-in-out;
  -ms-animation: scaler 0.3s normal forwards ease-in-out;
}